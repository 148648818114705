import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import Form from "./form"

const TeamStyles = styled.section`
  .team-content {
    margin: 12rem auto 3rem;

    &.container-full {
      padding: 0 10%;
    }

    .row {
      .col {
        flex: 1 1 40%;

        &:last-child {
          flex: 1 1 calc(60% - 6rem);
          margin-left: 6rem;
        }
      }
    }

    h1 {
      font-size: 2.2rem;
      line-height: 3rem;
      font-weight: 600;
    }

    .position {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 600;
    }

    .services {
      ul {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 300;
        font-style: italic;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          display: inline-block;

          &:before {
            content: "•";
            display: inline-block;
            margin: -0.6rem 1rem 0;
            font-size: 3rem;
            vertical-align: middle;
          }

          &:first-of-type {
            &:before {
              display: none;
            }
          }
        }
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      margin: 3rem 0;
    }

    .btn {
      color: #fff;
      background-color: var(--blue);
      border-color: var(--blue);
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background: none;
        color: var(--grey);
        border: 1px solid var(--grey);
      }
    }
  }

  .popup-wrap {
    position: fixed;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .popup-content {
      background: #fff;
      width: 80%;
      max-height: 80%;
      padding: 6rem;
      position: relative;

      .close {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 20px;
        right: 20px;
        cursor: pointer;
        border: 0;
        background: none;
        font-size: 2.5rem;
        outline: 0;
        transition: color 0.3s ease;

        svg {
          display: block;
        }

        &:hover {
          color: var(--blue);
        }
      }

      .row {
        margin: 0 -0.75rem;

        .col {
          margin: 0 0.75rem;
        }
      }

      .btn {
        float: right;
      }
    }

    .popup-overlay {
      position: fixed;
      top: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1080px) {
    .team-content {
      .services {
        li {
          display: block;

          &:first-of-type {
            &:before {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .team-content {
      .row {
        .col {
          flex: 1 1 100%;

          &:last-child {
            margin: 3rem 0 0;
          }
        }
      }
    }
  }
`

const Team = ({ data }) => {
  const acf = { ...data.acfTeam }

  const [popUp, setPopUp] = useState(0)

  const HandlePopUp = () => {
    const popUpState = !popUp ? 1 : 0
    setPopUp(popUpState)
  }

  return (
    <TeamStyles>
      <div className="team-content container container-full">
        <div className="row">
          <div className="col">
            {data.featuredImage &&
            data.featuredImage.node &&
            data.featuredImage.node.localFile &&
            data.featuredImage.node.localFile.childImageSharp &&
            data.featuredImage.node.localFile.childImageSharp.fluid ? (
              <Img
                fluid={data.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.featuredImage.node.altText}
              />
            ) : null}
          </div>
          <div className="col">
            {data.title ? <h1>{data.title}</h1> : null}

            {acf.position ? (
              <div className="position">{acf.position}</div>
            ) : null}

            {acf.services ? (
              <div
                className="services"
                dangerouslySetInnerHTML={{ __html: acf.services }}
              ></div>
            ) : null}

            {data.content ? (
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            ) : null}

            {acf.button && acf.button.title ? (
              <button className="btn" onClick={HandlePopUp}>
                {acf.button.title}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {popUp ? (
        <div className="popup-wrap">
          <div className="popup-content">
            <button class="close" onClick={HandlePopUp}>
              <FaTimes />
            </button>
            <div className="container container-full">
              <Form data={acf.button.submitProjectGfFormId} />
            </div>
          </div>
          <div className="popup-overlay"></div>
        </div>
      ) : null}
    </TeamStyles>
  )
}

export default Team
