import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-gravityforms-component"

const Form = ({ data }) => {
  const AllGravityData = () => {
    const { allGfForm } = useStaticQuery(
      graphql`
        query {
          allGfForm {
            edges {
              node {
                ...GravityFormComponent
              }
            }
          }
        }
      `
    )
    return allGfForm
  }

  const handleError = ({ values, error, reset }) => {
    const validationErrors = error.data.validation_messages
    const errorsWrap = document.querySelector(".validation_error")
    const yOffset = -120
    const y =
      errorsWrap.getBoundingClientRect().top + window.pageYOffset + yOffset
    let errors = ""

    Object.values(validationErrors).forEach(error => {
      errors += "<p>" + error + "</p>"
    })

    errorsWrap.innerHTML = errors

    window.scrollTo({ top: y, behavior: "smooth" })
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    // console.log(confirmations);
  }

  const id = parseInt(data)

  return (
    <div className="gform">
      {data ? (
        <GravityFormForm
          id={id}
          formData={AllGravityData()}
          lambda={process.env.GATSBY_GF_LAMBDA}
          successCallback={handleSuccess}
          errorCallback={handleError}
        />
      ) : null}
    </div>
  )
}

export default Form
